import React from 'react'
import NavBar from '../Layout/NavBar'
import Footer from '../Layout/Footer'
import ShaastraContent from '../Content/ShaastraContent'

function Shaastra() {
  return (
    <>
    <NavBar/>
    <ShaastraContent/>
    <Footer/>
      
    </>
  )
}

export default Shaastra
