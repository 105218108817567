import React from 'react'

function TechnicalArtContent() {
  return (
    <>
    <div className="home-container">          
            <div className="banner-area banner-inner-1" id="banner">
                <div className="banner-inner">                
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 leftpanel">
                                <h2 className="featured">Technical Art Challenge</h2>
                                <div className="thumb after-left-top">
                                    <img src="assets/img/contest/technicalart.jpg" alt="img" />
                                </div>
                            </div>
                            <div className="col-lg-6 align-self-center">
                                <div className="banner-details mt-4 mt-lg-0">
                                    <div className="row post-meta-single">
                                        <div className="col-7">
                                            <h2 className="aiml">Technical Art</h2>
                                        </div>
                                        <div className="col-5 mt-3 text-right">
                                            <b style={{ fontSize: "18px", color: "#ccce2c" }}><i class='fas fa-calendar-alt'></i>  Deadline: November 15<sup>th</sup> 2024</b>
                                        </div>
                                    </div>
                                    <h2 className="featured-head">Introducing the IIT Madras Shaastra Techathon, 'Technical Art Challenge!' </h2>
                                    <p>Student teams and individuals are invited to create technical art pieces based on the topics covered in previous issues of Shaastra magazine. The last date for registration will be November 15th, 2024. A tutorial with details on the contest will be presented by IIT Madras Shaastra experts with explainers on the rules of the Challenge. The Challenge details will be announced on November 20th. Submissions are due by November 25th, 2024. Winners will be announced on November 27th, 2024.<br/> <br /><b>Institutions with five or more participating teams will receive a certificate for 'Fostering an Environment for Creative and Technical Learning' from IIT Madras Shaastra.</b></p>
                                    <a className="btn btn-blue" style={{background: "darkgray", color: "rgb(0, 0, 0)", paddingTop: "11px"}} href="/register">Click here to register</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    </>
  )
}

export default TechnicalArtContent
