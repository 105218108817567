import React from 'react'

function TechnicalWritingContent() {
  return (
    <>
       <div className="home-container">          
            <div className="banner-area banner-inner-1 bg-black" id="banner">
                <div className="banner-inner">                
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 leftpanel">
                                <h2 className="featured">Technical Writing Challenge</h2>
                                <div className="thumb after-left-top">
                                    <img src="assets/img/contest/technicalwriting.jpg" alt="img" />
                                </div>
                            </div>
                            <div className="col-lg-6 align-self-center">
                                <div className="banner-details mt-4 mt-lg-0">
                                    <div className="row post-meta-single">
                                        <div className="col-7">
                                            <h2 className="aiml">Technical Writing Challenge</h2>
                                        </div>
                                        <div className="col-5 mt-3 text-right">
                                            <b style={{ fontSize: "18px", color: "#ccce2c" }}><i class='fas fa-calendar-alt'></i>  Deadline: July 31<sup>st</sup> 2024</b>
                                        </div>
                                    </div>
                                    <h2 className="featured-head">IIT Madras Shaastra Techathon is organising our first ever 'Technical Writing Challenge!'</h2>
                                    <p>Student teams are invited to craft a technical article based on a topic covered in the previous editions of Shaastra magazine. Register by July 31st, 2024 to participate. 
                                    Details for a live or recorded tutorial by IITM Shaastra Experts will be announced for effective participation in the challenge, the details of which will be announced on August 5<sup>th</sup> 2024.<br/> <br /><b>
                                    Institutions with five or more participating teams will receive a certificate for 'Fostering an Active Technical Learning Environment' from IIT Madras Shaastra.</b></p>
                                    <a className="btn btn-blue" style={{background: "darkgray", color: "rgb(0, 0, 0)", paddingTop: "11px"}} href="/register">Click here to register</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default TechnicalWritingContent
