import { useState } from "react";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function RegisterContent() {
    const [eventname, setEventname] = useState(undefined);
    const [institutename, setInstitutename] = useState('');
    const [instituteheadname, setInstituteheadname] = useState('');
    const [heademail, setHeademail] = useState('');
    const [headaddress, setHeadaddress] = useState('');
    const [contactno, setContactno] = useState('');
    const [teamname, setTeamname] = useState('');
    const [name1, setName1] = useState('');
    const [email1, setEmail1] = useState('');
    const [name2, setName2] = useState('');
    const [email2, setEmail2] = useState('');
    const [name3, setName3] = useState('');
    const [email3, setEmail3] = useState('');
    const [name4, setName4] = useState('');
    const [email4, setEmail4] = useState('');
    const [idproof, setIdproof] = useState(null);
    const [letter, setLetter] = useState(null);

    const options = [
        // "AI/ML challenge II",
        // "Technical writing challenge",
        "Engineering Innovation challenge",
        "Sci-Tech Quiz",
        "Technical art challenge",        
        "Innovation for the Society challenge"
    ];

    //Code for validation 
    function validateForm() {
        if (institutename.length === 0) {
            alert('Invalid Form, Name can not be empty')
            return
        }
        if (instituteheadname.length === 0) {
            alert('Invalid Form, Principal Name can not be empty')
            return
        }
        if (heademail.length === 0) {
            alert('Invalid Form, Principal Email can not be empty')
            return
        }
        if (headaddress.length === 0) {
            alert('Invalid Form, Principal Address can not be empty')
            return
        }
        if (contactno.length === 0) {
            alert('Invalid Form, Principal Contact Number can not be empty')
            return
        }
        if (teamname.length === 0) {
            alert('Invalid Form, Team Name can not be empty')
            return
        }
        if (name1.length === 0) {
            alert('Invalid Form, Participant1 Name can not be empty')
            return
        }
        if (email1.length === 0) {
            alert('Invalid Form, Participant1 Email can not be empty')
            return
        }
        if (name2.length === 0) {
            alert('Invalid Form, Participant2 Name can not be empty')
            return
        }
        if (email2.length === 0) {
            alert('Invalid Form, Participant2 Email can not be empty')
            return
        }
    }

    const uploadFile = async () => {
        let fData = new FormData();
        fData.append('eventname', eventname);
        fData.append('institutename', institutename);
        fData.append('instituteheadname', instituteheadname);
        fData.append('heademail', heademail);
        fData.append('headaddress', headaddress);
        fData.append('contactno', contactno);
        fData.append('teamname', teamname);
        fData.append('name1', name1);
        fData.append('email1', email1);
        fData.append('name2', name2);
        fData.append('email2', email2);
        fData.append('name3', name3);
        fData.append('email3', email3);
        fData.append('name4', name4);
        fData.append('email4', email4);
        fData.append('idproof', idproof);
        fData.append('letter', letter);

      // const response = await axios.post('http://localhost/shaastracrud/registrationnew.php', fData, {
        const response = await axios.post('https://shaastratechathon.in/shaastracrud/registrationnew.php', fData, {
            headers: { 'Content-Type': "multipart/form-data" }
        })
        console.log('The response is', response);
        if (response.data.message === 'Registered successfully, The files have been uploaded') {
            setTimeout(() => {
                toast.success("Registered successfully!",{
                  autoClose: 2000
                });
              }, 1500);               
        }
        else {
            toast.error('Registration failed, Follow the file naming convention before uploading the files', {
                autoClose: 2000,
              });            
        }
    };

    const handleSubmit = async (e) => { //when we use async, then we must use the await
        e.preventDefault();
        await uploadFile();
        e.target.reset();
    }

    return (
        <>
            <div className='banner-area banner-inner-1' id="regform">
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 '>
                            <form style={{ marginTop: "20px", marginBottom: "20px" }} onSubmit={handleSubmit}>
                                <h1 style={{color: "#5e2e90"}}>Register</h1>
                                <div style={{ alignItems: "center", justifyContent: "center", textAlign: "center" }}><a style={{ fontWeight: "600", color: "red", textTransform: "uppercase" }} href="assets/authorizationletter.pdf" target="_blank">Click here to view the approval letter format(Naming convention should be followed for ID proof and Approlval letter before submitting!)</a></div>


                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <label>Event</label>
                                        <select style={{ fontWeight: "700" }} name="eventname" id="eventname" onChange={(e) => setEventname(e.target.value)} required >
                                            <option >Select the event</option>
                                            {options.map((option, index) => {
                                                return (
                                                    <option key={index}>
                                                        {option}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="formInput">
                                            <label>Name of the Institution</label>
                                            <input
                                                name='institutename'
                                                id='institutename'
                                                type="text"
                                                placeholder="Name of the Institution"
                                                required
                                                onChange={(e) => setInstitutename(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="formInput">
                                            <label>Name of the Principal</label>
                                            <input
                                                name='instituteheadname'
                                                id='instituteheadname'
                                                type="text"
                                                placeholder="Name of the Principal"
                                                required
                                                onChange={(e) => setInstituteheadname(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="formInput">
                                            <label>Email ID of the Principal</label>
                                            <input
                                                name='heademail'
                                                id='heademail'
                                                type="email"
                                                placeholder="Email ID of the Principal"
                                                required
                                                onChange={(e) => setHeademail(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="formInput">
                                            <label>Address of the Institution</label>
                                            <input
                                                name='headaddress'
                                                id='headaddress'
                                                type="text"
                                                placeholder="Address of the Institution"
                                                required
                                                onChange={(e) => setHeadaddress(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="formInput">
                                            <label>Contact No. of the Principal</label>
                                            <input
                                                name='contactno'
                                                id='contactno'
                                                type="text"
                                                placeholder="Contact No. of the Principal"
                                                required
                                                onChange={(e) => setContactno(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="formInput">
                                            <label>Team Name</label>
                                            <input
                                                name='teamname'
                                                id='teamname'
                                                type="text"
                                                placeholder="Set a Team Name"
                                                required
                                                onChange={(e) => setTeamname(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="formInput">
                                            <label>Participant 1</label>
                                            <input
                                                name='name1'
                                                id='name1'
                                                type="text"
                                                placeholder="Name of Participant 1"
                                                required
                                                onChange={(e) => setName1(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="formInput">
                                            <label>Email of Participant1</label>
                                            <input
                                                name='email1'
                                                id='email1'
                                                type="email"
                                                placeholder="Email ID of Participant1"
                                                required
                                                onChange={(e) => setEmail1(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="formInput">
                                            <label>Participant 2</label>
                                            <input
                                                name='name2'
                                                id='name2'
                                                type="text"
                                                placeholder="Name of Participant 2"
                                                required
                                                onChange={(e) => setName2(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="formInput">
                                            <label>Email of Participant 2</label>
                                            <input
                                                name='email2'
                                                id='email2'
                                                type="email"
                                                placeholder="Email ID of Participant 2"
                                                required
                                                onChange={(e) => setEmail2(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="formInput">
                                            <label>Participant 3</label>
                                            <input
                                                name='name3'
                                                id='name3'
                                                type="text"
                                                placeholder="Name of Participant 3"
                                                onChange={(e) => setName3(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="formInput">
                                            <label>Email of Participant 3</label>
                                            <input
                                                name='email3'
                                                id='email3'
                                                type="email"
                                                placeholder="Email ID of Participant 3"
                                                onChange={(e) => setEmail3(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="formInput">
                                            <label>Participant 4</label>
                                            <input
                                                name='name4'
                                                id='name4'
                                                type="text"
                                                placeholder="Name of Participant 4"
                                                onChange={(e) => setName4(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="formInput">
                                            <label>Email of Participant 4</label>
                                            <input
                                                name='email4'
                                                id='email4'
                                                type="email"
                                                placeholder="Email ID of Participant 4"
                                                onChange={(e) => setEmail4(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="formInput">
                                            <label>ID Proof of all the Participants</label>
                                            <input
                                                name='idproof'
                                                id='idproof'
                                                type="file"
                                                accept=".pdf"
                                                placeholder="Upload ID proofs as a single pdf"
                                                required
                                                onChange={(e) => setIdproof(e.target.files[0])}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="formInput">
                                            <label>Letter of Approval from HOD / Principal</label>
                                            <input
                                                name='letter'
                                                id='letter'
                                                type="file"
                                                accept=".pdf"
                                                placeholder="Upload Approval Letter from the institute"
                                                required
                                                onChange={(e) => setLetter(e.target.files[0])}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-6 col-sm-12" style={{ textAlign: "center" }}>
                                    <button type='submit' className="btn btn-blue" 
                                    style={{ marginBottom: "20px", width: "200px", marginTop: "20px", background: "#ccce2c", color: "#5e2e90", fontWeight: "bold" }}>Submit</button><br />
                                    <ToastContainer />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default RegisterContent;