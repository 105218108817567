import React from 'react'
import Footer from '../Layout/Footer'
import TechnicalArtContent from '../Content/TechnicalArtContent'
import NavBar from '../Layout/NavBar'

function TechnicalArt() {
  return (
    <>
      <NavBar/>
      <TechnicalArtContent/>
      <Footer/>
    </>
  )
}

export default TechnicalArt
