import React from 'react'
import Footer from '../Layout/Footer'
//import WinnersContent from '../Content/WinnersContent'
import NavBar from '../Layout/NavBar'

function Winners() {
    return (
        <>
            <NavBar/>
            {/* <WinnersContent /> */}
            <Footer />
        </>
    )
}

export default Winners
