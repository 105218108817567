import { useState } from "react";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const notify1 = () => toast("We will contact you shortly!");

function ContactContent() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [msg, setMsg] = useState('');

    const [message, setMessage] = useState(''); // This is to use for the axios response 

    const uploadFile = async() => {        
        let fData = new FormData();
        fData.append('name', name);
        fData.append('email', email);
        fData.append('msg', msg);

        // const response = await axios.post('http://localhost/shaastracrud/enquiry.php', fData, {
        //     headers: {'Content-Type': "multipart/form-data"},
        //   })

          const response = await axios.post('https://shaastratechathon.in/shaastracrud/enquiry.php', fData, {
            headers: {'Content-Type': "multipart/form-data"},
          })

          if (response.data.success) {
            setMessage(response.data.success);        
          }
    };

    const handleSubmit = async(e) => { 
        e.preventDefault();
        await uploadFile();
    }

    return (
        <>
            <section className="contact_us" id='contact'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="contact_inner">
                                <div className="row">
                                    <div className="col-md-10 ">
                                        <div className="contact_form_inner">
                                            <div className="contact_field">
                                            <form onSubmit={handleSubmit}>
                                                <h3 style={{color: '#5e2e90'}}>To Subscribe</h3>
                                                <p>If you wish to subscribe to IIT Madras Shaastra Magazine, please share your details</p>
                                                <input type="text" className="form-control form-group" placeholder="Enter Your Name" name='name' id='name' required onChange={(e) => setName(e.target.value)} />
                                                <input className="form-control form-group" placeholder="Enter Your Email" name='email' id='email' type="email" required onChange={(e) => setEmail(e.target.value)} />
                                                <textarea className="form-control form-group" placeholder="Enter Your Message" name='msg' id='msg' type="text" required onChange={(e) => setMsg(e.target.value)}></textarea>
                                                <button type='submit' onClick={notify1} className="contact_form_submit" style={{ marginBottom: "20px", width: "200px", marginTop: "20px", background: '#124d81', color: '#5e2e90', fontWeight: "bold" }}>Send</button>
                                                <ToastContainer />
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="right_conatct_social_icon d-flex align-items-end">
                                            <div className="socil_item_inner d-flex">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="contact_info_sec">
                                    <h4 style={{color: '#5e2e90'}}>Contact Email</h4>
                                    <div className="d-flex info_single align-items-center">
                                        <span style={{ color: '#5e2e90' }}>  techathon2024@shaastramag.iitm.ac.in</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact_us1" id='contact'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="contact_inner" style={{marginBottom: "10px", marginTop: "10px"}}>
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <div className="contact_form_inner">
                                            <form onSubmit={handleSubmit}>
                                            <h3>To Subscribe</h3>
                                            <p>If you wish to subscribe to IIT Madras Shaastra Magazine, please share your details</p>
                                            <input type="text" className="form-control form-group" placeholder="Enter Your Name" name='name' id='name' required onChange={(e) => setName(e.target.value)} />
                                            <input className="form-control form-group" placeholder="Enter Your Email" name='email' id='email' type="email" required onChange={(e) => setEmail(e.target.value)} />
                                            <textarea className="form-control form-group" placeholder="Enter Your Message" name='msg' id='msg' type="text" required onChange={(e) => setMsg(e.target.value)}></textarea>
                                            <button onClick={notify1} type="submit" style={{marginTop: "5px"}} className="contact_form_submit">Send</button>
                                            <ToastContainer/>
                                            </form>
                                        </div>
                                    </div>
                                    <div className='col-sm-12'>
                                        <div className="d-flex info_single align-items-center" style={{ alignItems: "center", padding: "5px" }}>
                                            <p style={{ color: '#000', paddingTop: "2px", fontSize: "10px" }}>Contact: techathon2024@shaastramag.iitm.ac.in</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactContent
