import React from 'react';

function WinnersTable() {
  return (
    <>
      <div class='main outer-wrapper'>
        <table class="heavyTable">
          <thead className='table-head'>
            <tr>
              <th>Team Name</th>
              <th>Institution</th>
              <th>Students</th>
            </tr>
          </thead>
          <tbody className='tbody'>
            <tr>
              <td style={{textAlign: "center"}}>Code Crusaders</td>
              <td style={{textAlign: "center"}}>SRM Easwari College</td>
              <td style={{textAlign: "center"}}>Manisha V, Pallavi N, Mohamed Hasan MB, Joshua Benedict</td>
            </tr>
            <tr>
              <td style={{textAlign: "center"}}>Virtual Verbatim</td>
              <td style={{textAlign: "center"}}>Kongu Engineering College</td>
              <td style={{textAlign: "center"}}>Mathu Mathi M, Soundariya M, Subaharini C, Tarun Raina</td>
            </tr>
            <tr>
              <td style={{textAlign: "center"}}>Anonymous Authors</td>
              <td style={{textAlign: "center"}}>St. Joseph's College of Engineering</td>
              <td style={{textAlign: "center"}}>Maria Liz Mathew, Maneesh V, Nidhisha B, Sasirekha M</td>
            </tr>
            <tr>
              <td style={{textAlign: "center"}}>Incredibles</td>
              <td style={{textAlign: "center"}}>SRM Easwari College</td>
              <td style={{textAlign: "center"}}>Lakshmanan K, Nithin A, Nivasini N N, Kanimozhi D</td>
            </tr>
            <tr>
              <td style={{textAlign: "center"}}>Tech Orcas</td>
              <td style={{textAlign: "center"}}>St. Joseph's College of Engineering</td>
              <td style={{textAlign: "center"}}>M Mouseena Banu, Maheswaran A S, Joshika P, Raghav Vyas</td>
            </tr>
            <tr>
              <td style={{textAlign: "center"}}>Techies</td>
              <td style={{textAlign: "center"}}>SRM Easwari College</td>
              <td style={{textAlign: "center"}}>Shree Harini T, Sathya R K, Shreya K, Shrinithi P</td>
            </tr>
            <tr>
              <td style={{textAlign: "center"}}>Quillstack</td>
              <td style={{textAlign: "center"}}>St. Joseph's College of Engineering</td>
              <td style={{textAlign: "center"}}>Mokhithraa J, Nilavezhil Ovia V P, Reina Shannen J, Swathija T</td>
            </tr>
            <tr>
              <td style={{textAlign: "center"}}>Tech Wizards</td>
              <td style={{textAlign: "center"}}>SVKM's Narsee Monjee College</td>
              <td style={{textAlign: "center"}}>Dia Mistry, Mansi Vyas, Prashmi Sharma, Riddhi Gosalia</td>
            </tr>
            <tr>
              <td style={{textAlign: "center"}}>archITects</td>
              <td style={{textAlign: "center"}}>SVKM's Narsee Monjee College</td>
              <td style={{textAlign: "center"}}>Kresha Gandhi, Nadiya Machiwala, Nirmita Anerao</td>
            </tr>
          </tbody>
          </table>
          </div>


        <h4 className='title-winners'>AI/ML contest II - <span style={{color: "#fff"}}>Winners</span></h4>
      <div class='main outer-wrapper'>
        <table class="heavyTable">
          <thead className='table-head'>
            <tr>
              <th>Team Name</th>
              <th>Institution</th>
              <th>Students</th>
            </tr>
          </thead>
          <tbody className='tbody'>
            <tr>
              <td style={{textAlign: "center"}}>Edgerunners</td>
              <td style={{textAlign: "center"}}>St. Joseph's College of Engineering</td>
              <td style={{textAlign: "center"}}>Vendhan U, Naveen Kumar S, Paulin A, Pavendhan D</td>
            </tr>
            <tr>
              <td style={{textAlign: "center"}}>Frontiers</td>
              <td style={{textAlign: "center"}}>St. Joseph's College of Engineering</td>
              <td style={{textAlign: "center"}}>Mohana R, Prithiv P M, Roshini A, Sanjay J</td>
            </tr>
            <tr>
              <td style={{textAlign: "center"}}>HACK_ELITE</td>
              <td style={{textAlign: "center"}}>St. Joseph's College of Engineering</td>
              <td style={{textAlign: "center"}}>Rakshana R, Shruthi M, Tharukesh SD, Yogesh G</td>
            </tr>
            <tr>
              <td style={{textAlign: "center"}}>ID3</td>
              <td style={{textAlign: "center"}}>St. Joseph's College of Engineering</td>
              <td style={{textAlign: "center"}}>Swetha K, Mokhithraa J, Sahastrajit R</td>
            </tr>
            <tr>
              <td style={{textAlign: "center"}}>MACHINE MAVERICKS</td>
              <td style={{textAlign: "center"}}>St. Joseph's College of Engineering</td>
              <td style={{textAlign: "center"}}>Pravin K, Bhuvanesh M, Praneesh Roshan P, Preetam Tony J</td>
            </tr>
            <tr>
              <td style={{textAlign: "center"}}>NeuroSparks</td>
              <td style={{textAlign: "center"}}>Velammal Engineering College</td>
              <td style={{textAlign: "center"}}>Abinand P, Ashwin N, Fahrudeen M, KamilHassan A</td>
            </tr>
            <tr>
              <td style={{textAlign: "center"}}>Team Nexus</td>
              <td style={{textAlign: "center"}}>St. Joseph's College of Engineering</td>
              <td style={{textAlign: "center"}}>Benedict Raymond X, Arlin Robeiksha Britto, Gokila Harini S, Arnesh R</td>
            </tr>
          </tbody>
          </table>
          </div>

          <h4 className='title-winners'>AI/ML contest I - <span style={{color: "#fff"}}>Winners</span></h4>
          <div class='main outer-wrapper'>          
          <table class="heavyTable">
          <thead className='table-head'>
            <tr>
              <th>Team Name</th>
              <th>Institution</th>
              <th>Students</th>
            </tr>
          </thead>
           <tbody className='tbody'>
            <tr>
              <td style={{textAlign: "center"}}>10 Conquerors</td>
              <td style={{textAlign: "center"}}>Mohammed Sathak A J College of Engineering</td>
              <td style={{textAlign: "center"}}>Abu Jabbar Mubarak, Mohammed Abdul Kalam, Mohammed Fazil & Suriya Balaji</td>
            </tr>
            <tr>
              <td style={{textAlign: "center"}}>AI Spirits</td>
              <td style={{textAlign: "center"}}>Saveetha Engineering College</td>
              <td style={{textAlign: "center"}}>Kaushika A, Pradeesh S & Yamunaasri T S</td>
            </tr>
            <tr>
              <td style={{textAlign: "center"}}>Hack Elite</td>
              <td style={{textAlign: "center"}}>St. Joseph's College of Engineering</td>
              <td style={{textAlign: "center"}}>Rakshanaa R, Shruthi M, Tharukesh S D & Yogesh G</td>
            </tr>
            <tr>
              <td style={{textAlign: "center"}}>IITMZ</td>
              <td style={{textAlign: "center"}}>Indian Institute of Technology Madras, Zanzibar Campus</td>
              <td style={{textAlign: "center"}}>Manas Pandya, Sri Advaita Varakavi & Sai Adith Prakash</td>
            </tr>
            <tr>
              <td style={{textAlign: "center"}}>Nova Spark</td>
              <td style={{textAlign: "center"}}>RMK Engineering College</td>
              <td style={{textAlign: "center"}}>Kaviyarasu S, Jeyanth S, Keshavardhan D R & Ashraf Deen A</td>
            </tr>
            <tr>
              <td style={{textAlign: "center"}}>Pioneers</td>
              <td style={{textAlign: "center"}}>St. Joseph's College of Engineering</td>
              <td style={{textAlign: "center"}}>Maria Liz Mathew, Maneesh V, Nidhisha B & Sasirekha M</td>
            </tr>
            <tr>
              <td style={{textAlign: "center"}}>SEC AI LEGENDS</td>
              <td style={{textAlign: "center"}}>Saveetha Engineering College</td>
              <td style={{textAlign: "center"}}>Mahinkumar T, Kavinraja D, Thiyagarajan A, Karnala Santhan Kumar</td>
            </tr>
          </tbody> 
          </table>
          </div>

    </>
  )
}
export default WinnersTable;