import React from 'react'

function SocietyInnovationContent() {
  return (
    <>
      <div className="home-container">          
            <div className="banner-area banner-inner-1" id="banner">
                <div className="banner-inner">                
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 leftpanel">
                                <h2 className="featured">Innovation for the Society challenge</h2>
                                <div className="thumb after-left-top">
                                    <img src="assets/img/contest/innoforsociety.jpg" alt="img" />
                                </div>
                            </div>
                            <div className="col-lg-6 align-self-center">
                                <div className="banner-details mt-4 mt-lg-0">
                                    <div className="row post-meta-single">
                                        <div className="col-7">
                                            <h2 className="aiml">Innovation for the Society</h2>
                                        </div>
                                        <div className="col-5 mt-3 text-right">
                                            <b style={{ fontSize: "18px", color: "#ccce2c" }}><i class='fas fa-calendar-alt'></i>  Deadline: December 31<sup>st</sup> 2024</b>
                                        </div>
                                    </div>
                                    <h2 className="featured-head">Join the IIT Madras Shaastra Techathon ‘Innovation for Society Challenge!’ </h2>
                                    <p>Student teams are challenged to present a creative innovation addressing a topic of societal interest. Register your team by December 31st, 2024, for this online challenge. Top teams may even be invited to present in person! Challenge details will be announced on January 1st, 2025. Stay tuned for further updates. <br/> <br /><b>Institutions with five or more participating teams will receive a certificate for 'Fostering an Environment for Innovation for Social Causes'.</b></p>
                                    <a className="btn btn-blue" style={{background: "darkgray", color: "rgb(0, 0, 0)", paddingTop: "11px"}} href="/register">Click here to register</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    </>
  )
}

export default SocietyInnovationContent
