import React from 'react'

function ShaastraContent() {
    return (
        <>
            <div className="container-fluid p-1 p-sm-5" style={{background: "#19224d"}}>
                <div className="row bg-light no-gutters p-1 p-sm-5">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="container mx-auto">
                            <img style={{width: "100%", maxHeight: "700px"}} className="img-fluid ilist-c pt-3 text-center" src="assets/img/mag.png" alt='magazine'/>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-lg-6 col-xl-6">
                        <div className="container mx-auto p-2 p-sm-2 p-lg-4">
                            <div className="row py-2 py-sm-2 py-lg-1">
                                <div className="col-12"><h1 className="text-center mb-3 mt-4 mt-lg-2" style={{fontWeight: "700", color: "#5e2e90"}}>About IIT Madras Shaastra</h1></div>
                                <div className="col-12 col-md-6">
                                    <div className="contentnew">
                                    IIT Madras Shaastra is India’s only science, technology and innovation
                                    magazine, published by an Institute of Eminence. Brought out by a team
                                    of professional science journalists, it packs robustly researched,
                                    insightful and informative content.<br/>                                Shaastra, pioneered by the Indian Institute of Technology, Madras, is
                                the first science and technology magazine from an Indian educational
                                institution. It aims to bring a solution-driven perspective to
                                developments in science and technology while helping the readers make
                                sense of their impact on society.
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    Shaastra is intended to serve as a
                                    platform to enable informed conversations between industry, academia,
                                    and policymakers.
                                    <br /><br />
                                    IIT Madras Shaastra magazine currently reaches out to a diverse set of
                                    readers including students, academia, investors, influencers and
                                    policymakers.
                                    <div className="text-center h4 mt-5">
                                    <a className='linkshaas' href="https://shaastramag.iitm.ac.in/" target="_blank" rel="noreferrer" style={{color: "#5e2e90"}}>Click here to know more about IIT Madras Shaastra! </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ShaastraContent
