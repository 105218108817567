import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../Pages/Home";
import Winners from "../Pages/Winners";
import Contact from "../Pages/Contact";
import Register from "../Pages/Register";
import TechnicalWriting from "../Pages/TechnicalWriting";
import TechnicalArt from "../Pages/TechnicalArt";
import EnggInnovation from "../Pages/EnggInnovation";
import SciTechQuiz from "../Pages/SciTechQuiz";
import SocietyInnovation from "../Pages/SocietyInnovation";
import Shaastra from "../Pages/Shaastra";
import Gallery from "../Pages/Gallery";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path='/' element={<Home/>}></Route>
          <Route exact path='/winners' element={<Winners/>}></Route>
          <Route exact path='/contact' element={<Contact/>}></Route>
          <Route exact path='/register' element={<Register/>}></Route>
          <Route exact path='/shaastra' element={<Shaastra/>}></Route>
          <Route exact path='/writing' element={<TechnicalWriting/>}></Route>
          <Route exact path='/art' element={<TechnicalArt/>}></Route>
          <Route exact path='/engg' element={<EnggInnovation/>}></Route>
          <Route exact path='/scitech' element={<SciTechQuiz/>}></Route>
          <Route exact path='/society' element={<SocietyInnovation/>}></Route>
          <Route exact path='/gallery' element={< Gallery/>}></Route>
        </Routes>
      </Router>
    </div>
  );
}
export default App;