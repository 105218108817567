import React from 'react'
import Footer from '../Layout/Footer'
import HomeContent from '../Content/HomeContent'
import ContactContent from '../Content/ContactContent'
import Slider from '../Layout/Slider'
import NavBar from '../Layout/NavBar';

function Home() {
  return (
    <>
    <NavBar/>
    <Slider/>
    <HomeContent/>
    <ContactContent/>    
    <Footer/>      
    </>
  )
}

export default Home
