import React from 'react'

function Footer() {
  return (
    <>
        <div className="footer-area">
        <div className="container">
            <div className="footer-bottom text-center">
                <p style={{color: "#fff"}}>Copyright ©2024 <a style={{color: "#fff", textDecoration: "none"}} href="/">  IIT Madras Shaastra Techathon</a></p>
            </div>
        </div>
    </div>
    </>
  )
}

export default Footer