import React from 'react'

function EnggInnovationContent() {
  return (
    <>
    <div className="home-container">          
            <div className="banner-area banner-inner-1" id="banner">
                <div className="banner-inner">                
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 leftpanel">
                                <h2 className="featured">Engineering Innovation challenge</h2>
                                <div className="thumb after-left-top">
                                    <img src="assets/img/contest/engginno.jpg" alt="img" />
                                </div>
                            </div>
                            <div className="col-lg-6 align-self-center">
                                <div className="banner-details mt-4 mt-lg-0">
                                    <div className="row post-meta-single">
                                        <div className="col-7">
                                            <h2 className="aiml">Engineering Innovation</h2>
                                        </div>
                                        <div className="col-5 mt-3 text-right">
                                            <b style={{ fontSize: "18px", color: "#ccce2c" }}><i class='fas fa-calendar-alt'></i>  Deadline: November 30<sup>th</sup> 2024</b>
                                        </div>
                                    </div>
                                    <h2 className="featured-head">Announcing the IIT Madras Shaastra Techathon ‘Engineering Innovation Challenge!’  </h2>
                                    <p>Student teams are tasked with presenting a creative engineering innovation in a topical area. Register your team by November 30th, 2024, for this online challenge. Top teams may even get the chance to present in person at the IIT Madras campus in Chennai! Challenge details will be revealed on December 4th, 2024. Stay tuned for further updates.<br/> <br /><b>Institutions with five or more participating teams will receive a certificate for 'Fostering an Environment for Technical Innovation'.</b></p>
                                    <a className="btn btn-blue" style={{background: "darkgray", color: "rgb(0, 0, 0)", paddingTop: "11px"}} href="/register">Click here to register</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    </>
  )
}

export default EnggInnovationContent
