import React from 'react'
import Footer from '../Layout/Footer'
import NavBar from '../Layout/NavBar'

function SciTechQuiz() {
  return (
    <>
      <NavBar/>
      <SciTechQuiz/>
      <Footer/>
    </>
  )
}

export default SciTechQuiz
