import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./CardSlider.css";
// import '../../public/assets/css/cardslider.css'

const CardSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const cards = [
    {
      id: 1,
      title: "Mohammed Sathak AJ College of Engineering",
      content: "Certificate of Appreciation for fostering AI/ML Environment presented to Dr K S Srinivasan Principal along with Prof. Devi Kala, Head of student Affairs.",
      image: "assets/img/winners/md_sathak_college.jpeg",
    },
    {
      id: 2,
      title: "St Josephs College of Engineering",
      content: "Certificate of Appreciation for fostering AI/ML Environment presented to Dr V Seshagiri Rao, Principal, Dr Lily Raamesh, HOD AI/ML department along with the winning team Rakshanaa R, Shruthi M, Tharukesh S D & Yogesh G",
      image: "assets/img/winners/st_joseph_1.jpg",
    },
    {
      id: 3,
      title: "Kongu Engineering College, Perundurai",
      content: "Certificate of Appreciation for fostering AI/ML Environment presented to Dr V Balusamy, Principal. Also present Dr S Kalaiselvi HOD, Computer Technology Department.",
      image: "assets/img/winners/kongu1.jpeg",
    },
    {
      id: 4,
      title: "Kongu Engineering College",
      content: "Team from Kongu Engineering College along with the Principal Dr V Balusamy, Dr S Kalaiselvi HOD Computer Technology Department, Dr Vimaladevi Asst HOD Computer Technology Department",
      image: "assets/img/winners/kongu2.jpeg",
    },
    {
      id: 5,
      title: "Bannari Amman Institute of Technology, Sathyamangalam",
      content: "Certificate of Appreciation for fostering AI/ML Environment presented to Dr C Palanisamy, Principal, Bannari Amman Institute of Technology, Sathyamangalam",
      image: "assets/img/winners/bannari.jpeg",
    },
    {
      id: 6,
      title: "St Josephs College of Engineering",
      content: "Certificate of Appreciation for fostering AI/ML Environment presented to Dr. V Seshagiri Rao, Principal St Josephs College of Engineering, along with his team of Deans",
      image: "assets/img/winners/st_joseph_2.jpg",
    },
  ];

  return (
    <>
    <div id="memorylane">
    {/* <p className="memory-down">Down the Memory Lane</p> */}
    </div>
    <div className="carousel-container" >
      <div className="cardcontainer">        
      <Slider {...settings}>
        {cards.map((card) => (
          <div key={card.id} className="card">
            <img src={card.image} alt={card.title} className="card-image" />
            <div className="card-content">
              <h3>{card.title}</h3>
              <p>{card.content}</p>
            </div>
          </div>
        ))}
      </Slider>
      </div>
    </div>
    </>
  );
};
export default CardSlider;