import React from 'react'
import Footer from '../Layout/Footer'
import EnggInnovationContent from '../Content/EnggInnovationContent'
import NavBar from '../Layout/NavBar'

function EnggInnovation() {
  return (
    <>
      <NavBar/>
      <EnggInnovationContent/>
      <Footer/>
    </>
  )
}

export default EnggInnovation
