import React from 'react'
import NavBar from '../Layout/NavBar'
// import GalleryContent from '../Content/GalleryContent'
import Footer from '../Layout/Footer'
import WinnersTable from '../Content/WinnersTable'
import CardSlider from '../Content/CardSlider'

function Gallery() {
  return (
    <>
      <NavBar/>
      <h4 className='title-winners'>Technical Writing - <span style={{color: "#fff"}}>Winners</span></h4>
      <WinnersTable/>
      <CardSlider/>
      {/* <GalleryContent/> */}
      <Footer/>
      
    </>
  )
}
export default Gallery;