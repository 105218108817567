import React from 'react'
import Footer from '../Layout/Footer'
import RegisterContent from '../Content/RegisterContent'
import NavBar from '../Layout/NavBar'

function Register() {
  return (
    <>
      <NavBar/>
      <RegisterContent/>
      <Footer/>
    </>
  )
}

export default Register
