import React from 'react'
import Footer from '../Layout/Footer'
import TechnicalWritingContent from '../Content/TechnicalWritingContent'
import NavBar from '../Layout/NavBar'

function TechnicalWriting() {
  return (
    <>
      <NavBar/>
      <TechnicalWritingContent/>
      <Footer/>
    </>
  )
}

export default TechnicalWriting
