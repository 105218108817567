import React from 'react'
import Footer from '../Layout/Footer'
import SocietyInnovationContent from '../Content/SocietyInnovationContent'
import NavBar from '../Layout/NavBar'

function SocietyInnovation() {
  return (
    <>
      <NavBar/>
      <SocietyInnovationContent/>
      <Footer/>
    </>
  )
}

export default SocietyInnovation
