import React from 'react'
import Footer from '../Layout/Footer'
import ContactContent from '../Content/ContactContent'
import NavBar from '../Layout/NavBar'

function Contact() {
  return (
    <>
      <NavBar/>
      <ContactContent/>
      <Footer/>
    </>
  )
}

export default Contact
